import React, { useState } from 'react'
import {
    closemen,
    logo3, logo4, logo5, logo6, logo7, logo8, img01, img02, t03, img03, img04, t05, img05, t06, img06, locationicon, locationbook, whitetick, amit, boris, arc2
} from '../../assets/img';
import './Home.css';



const Home = ({ onConsultationClick }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    }
    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className='d-none d-md-block' style={{ background: '#37554B', height: '756px' }}>
                <div className='container py-5'>
                    <div className='row py-5 '>
                        <div className='col-md-8 col-12 py-5 d-flex text-white '>
                            <div>
                                <p className=' mb-0' style={{ fontSize: '50px' }}>Embrace Your</p>
                                <h1 className='mb-0' style={{ fontSize: '150px', lineHeight: '85%', fontWeight: '700' }}>Mental
                                    Health</h1>
                            </div>
                            <img src={closemen} alt='' className='img-fluid  closemen' />

                        </div>
                        <div className='col-md-4 col-12 text-white text-end'>
                            <div className='top-0'>
                                <p>Visit us at to learn more about our services and to schedule your appointment. Let us help you take the first step towards better mental health today.</p>
                                <a href='#' className='text-decoration-none  ' style={{ borderBottom: '1px solid #98C647', color: '#98C647' }} onClick={onConsultationClick}>Go to Link <i class="bi bi-arrow-right"></i></a>
                            </div>
                            <div className='pt-5 mt-5'>
                                <div className='mt-5 pt-5'>
                                    <button type="button" class="btn btn-outline-light px-3 py-2 rounded-4 w-50 my-5 rotate-button" onClick={onConsultationClick} >Therapy / Counseling</button><br />
                                    <button type="button" class="btn btn-outline-light px-3 py-2 rounded-4 w-50 my-4 rotate-button2" onClick={onConsultationClick}>TMS Therapy</button><br />
                                    <button type="button" class="btn btn-outline-light px-3 py-2 rounded-4 w-50 my-1  " onClick={onConsultationClick}>Genetic Testing</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className='d-block d-md-none' style={{ background: '#37554B', minHeight: '756px' }}>
                <div className='container py-2'>
                    <div className='row '>
                        <div className='col-md-8 col-12  text-white d-flex flex-column flex-md-row align-items-md-start'>
                            <div className='form_men'>
                                <p className='mb-0 text-center' style={{ fontSize: '2rem' }}>Embrace Your</p>
                                <h1 className='mb-0 text-center' style={{ fontSize: '4.5rem', lineHeight: '85%', fontWeight: '700' }}>Mental Health</h1>
                            </div>
                            <img src={closemen} alt='' className='img-fluid  ' />
                        </div>
                        <div className='col-md-4 col-12 text-white text-md-end text-center'>
                            <div className='top-0'>
                                <p>Visit us to learn more about our services and to schedule your appointment. Let us help you take the first step towards better mental health today.</p>
                                <a href='#' className='text-decoration-none' style={{ borderBottom: '1px solid #98C647', color: '#98C647' }} onClick={onConsultationClick} >Go to Link <i className="bi bi-arrow-right"></i></a>
                            </div>
                            <div className='pt-2 '>
                                <div className=''>
                                    <button type="button" className="btn btn-outline-light px-3 py-2 rounded-4 w-100 my-3 " onClick={onConsultationClick} >Therapy / Counseling</button><br />
                                    <button type="button" className="btn btn-outline-light px-3 py-2 rounded-4 w-100  " onClick={onConsultationClick}>TMS Therapy</button><br />
                                    <button type="button" className="btn btn-outline-light px-3 py-2 rounded-4 w-100 my-3" onClick={onConsultationClick}>Genetic Testing</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container text-center py-md-5 my-5  border-3  border-secondery'>
                <h2 className='customFont add_head_data fw-bold mb-0 mb-md-1 '><b>Covered by most insurance plans </b></h2>
                <i className='mt-2 add_itlic_data'>We handle the paper work so you can focus on treatment </i>
                <section className='wrapper mt-3'>
                    <div className='slider'>
                        <div className='d-flex'>
                            <div className='  '>
                                <img src={logo3} className='w-75'></img>
                            </div>
                            <div className='  '>
                                <img src={logo4} className='w-75 '></img>
                            </div>
                            <div className='  '>
                                <img src={logo5} className='w-75'></img>
                            </div>
                            <div className=' '>
                                <img src={logo6} className='w-75'></img>
                            </div>
                            <div className='  '>
                                <img src={logo7} className='w-75'></img>
                            </div>
                            <div className='  '>
                                <img src={logo8} className='w-75'></img>
                            </div>

                        </div>
                    </div>
                </section>
                <p className='m-0 p-0 d-flex align-items-center justify-content-center  add_itlic_data'>
                    and many more!
                </p>
            </div>
            <div className='container'>
                <p className='p-0 m-0 d-flex align-items-center justify-content-end text-black-50  tc pb-1'><i className='ms-2'>
                    T&C Apply
                </i></p>
            </div>
            <div className='backimg py-md-5 '>
                <div className='container'>
                    <h1 className='text-center py-3 '> <b>Aera of Expertise</b></h1>
                    <div className='row my-md-5 my-1'>
                        <div className='col-md-6 col-12 text-md-end  text-end py-4 px-3 order-md-1 order-2'>
                            <h2><b>TMS</b></h2>
                            <p className='text_justify'>Transcranial Magnetic Stimulation (TMS) offers numerous advantages over prescribed medications for treating mental health issues. TMS is a safe, effective non-drug treatment for depression.</p>
                        </div>
                        <div className='col-md-6 col-12 px-4 order-md-2 order-1 ' >
                            <img src={img01} className='w-100'></img>
                        </div>
                    </div>
                    <div className='row my-md-5 my-1'>
                        <div className='col-md-6 col-12 px-4  ' >
                            <img src={img02} className='w-100'></img>
                        </div>
                        <div className='col-md-6 col-12 text-md-start  text-start py-4 px-3'>
                            <h2><b>Therapy / Counseling</b></h2>
                            <p className='text_justify'>In a collaborative setting, you and a counselor will work together to reach optimal mental health by identifying goals and solutions to problems; improving communication and coping skills; and strengthening self-esteem.
                            </p>
                        </div>

                    </div>
                    <div className='row my-md-5 my-1'>
                        <div className='col-md-6 col-12 text-md-end text-end py-4 px-3 order-md-1 order-2'>
                            <h2><b>Medication Management</b></h2>
                            <p className='text_justify'>Medication Management helps to ensure you are receiving optimal therapeutic outcomes for the prescription medications you are taking. Our providers will review your current medications, along with their dosage, to see if any adjustments should be made.</p>
                        </div>
                        <div className='col-md-6 col-12 px-4 order-md-2 order-1 ' >
                            <img src={t03} className='me-md-5 me-4' style={{ width: '30%' }}></img>
                            <img src={img03} className=' ' style={{ width: '60%' }} ></img>
                        </div>
                    </div>
                    <div className='row my-md-5 my-1'>
                        <div className='col-md-6 col-12 px-4 ' >
                            <img src={img04} className='w-100'></img>
                        </div>
                        <div className='col-md-6 col-12 text-md-start  text-end py-4 px-3'>
                            <h2><b>Genetic Testing</b></h2>
                            <p className='text_justify'>Psychogenomic testing, also known as pharmacogenomic testing, is a form of genetic testing that examines an individual's DNA to understand how their genetic makeup can affect their response to certain medications. This type of testing aims to provide personalized treatment plans based on the genetic variations that influence drug metabolism and efficacy.
                            </p>
                        </div>

                    </div>
                    <div className='row my-md-5 my-1'>
                        <div className='col-md-6 col-12 text-md-end  text-start py-4 px-3 order-md-1 order-2'>
                            <h2><b>Psychological Testing and Assessment</b></h2>
                            <p className='text_justify'>At ARC Psychiatry, we understand how important it is to get a clear picture of your mental health. That’s why we offer comprehensive psychological testing services to help you understand your cognitive, emotional, and behavioral functioning.</p>
                        </div>
                        <div className='col-md-6 col-12 px-4 order-md-2 order-1' >
                            <img src={t05} className='me-md-5 me-4' style={{ width: '30%' }}></img>
                            <img src={img05} className=' ' style={{ width: '60%' }} ></img>
                        </div>
                    </div>
                    <div className='row my-md-5 my-1'>
                        <div className='col-md-6 col-12 px-4 ' >
                            <img src={img06} className=' me-md-5 me-4' style={{ width: '60%' }} ></img>
                            <img src={t06} className='' style={{ width: '30%' }}></img>

                        </div>
                        <div className='col-md-6 col-12 text-md-start  text-end py-4 px-3'>
                            <h2><b>Telemedicine Services</b></h2>
                            <p className='text_justify'>At ARC Psychiatry, we are dedicated to providing accessible, high-quality mental health care to our patients. We now offer comprehensive telemedicine services, allowing you to receive expert psychiatric care from the comfort and safety of your home.</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className=' container border-bottom  border-3  border-secondery py-md-5 pb-0 pt-md-5 pt-0 '>
                <div className='bg-img py-md-5 py-3'>
                    {/* <img src={bgimg} alt='' /> */}
                    <div className='text-center'>
                        <img className='location' src={locationbook} />
                        <h1 className='my-3 fw-bold customFont add_head_data'>Find your closest ARC Psychiatry TMS </h1>
                        <p className='add_itlic_data'>We have convenient locations close to home, work, or <br />
                            school, so that you’re able to fit TMS into your schedule</p>
                    </div>
                    <div className='row mt-md-5 mt-0 d-flex align-items-center justify-content-center jstify-content-md-start ps-md-5 '>
                        <div className='col-md col-6  border_right' >
                            <a className='texta' href={'https://maps.app.goo.gl/YTCn7tJdfX7zuQ3F6'} target='_blank'>

                                <div className='row'>
                                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                                        <img src={locationicon} className='img-icom2 '></img>
                                    </div>
                                    <div className='col-8 ps-0'>
                                        <h3 className='text-color fs-5'>Beachwood  </h3>
                                        <p className='add_font_small'> <b>25700 Science Park, Suite 210,
                                            Beachwood, OH 44122.</b> </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='col-md col-6 border_right' >
                            {/* <a href={'https://maps.app.goo.gl/ePMestN2wSweSwhu7'} target='_blank'>

                  <div className='row setheight'>
                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                      <img src={locationicon} className='img-icom2 '></img>
                    </div>
                    <div className='col-8 ps-0'>
                      <h3 className='text-color fs-5'></h3>
                      <p className='add_font_small '><b> 4510 Dressler Rd NW Canton, OH 44718</b> </p>
                    </div>
                  </div>
                </a> */}
                            <a href={'https://maps.app.goo.gl/ePMestN2wSweSwhu7'} target='_blank' className='d-block d-md-none texta'>

                                <div className='row'>
                                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                                        <img src={locationicon} className='img-icom2 '></img>
                                    </div>
                                    <div className='col-8 ps-0'>
                                        <h3 className='text-color fs-5'>Canton </h3>
                                        <p className='add_font_small'> <b>4510 <br /> Dressler <br /> Rd NW Canton,<br /> OH 44718</b> </p>
                                    </div>
                                </div>
                            </a>
                            <a href={'https://maps.app.goo.gl/ePMestN2wSweSwhu7'} target='_blank' className='d-none d-md-block texta'>

                                <div className='row'>
                                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                                        <img src={locationicon} className='img-icom2 '></img>
                                    </div>
                                    <div className='col-8 ps-0'>
                                        <h3 className='text-color fs-5'>Canton </h3>
                                        <p className='add_font_small'> <b>4510 Dressler Rd NW Canton, OH 44718</b> </p>
                                    </div>
                                </div>
                            </a>

                        </div>
                        <div className='col-md col-6 border_right'>
                            <a className='texta' href={'https://maps.app.goo.gl/RegzWDKwBGdcyc6x5'} target='_blank'>
                                <div className='row'>
                                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                                        <img src={locationicon} className='img-icom2 '></img>
                                    </div>
                                    <div className='col-8 ps-0'>
                                        <h3 className='text-color fs-5'>Medina  </h3>
                                        <p className='add_font_small '><b>3591 Reserve Commons, Suite 100, Medina, OH 44256</b> </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='col-md col-6'>
                            <a className='texta' href={'https://maps.app.goo.gl/mWsTkmKh7QmcE5Dj8'} target='_blank'>

                                <div className='row'>
                                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                                        <img src={locationicon} className='img-icom2 '></img>
                                    </div>
                                    <div className='col-8 ps-0'>
                                        <h3 className='text-color fs-5'>Westlake </h3>
                                        <p className='add_font_small'><b>29055 Clemens Rd.,Suite<br className='d-md-none d-block' /> A  Westlake, OH  44145</b> </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='bg-color  mt-md-5 mt-2  '>
                    <div className='container text-white  '>
                        <div className='row py-md-5 py-4 mx-auto  '>
                            <div className='col-12 col-md-8  text-md-start text-center'>
                                <h4 className='mb-0 mb-md-1'><b className='fs-3'>Ready to get started?</b></h4>
                                <h4 className='fw-normal mb-0 mb-md-1  fs-5'>You can do this, we can help.
                                </h4>
                                <div className='row py-md-4 py-3 d-flex justify-content-end'>
                                    <div className='col-md col-9 d-flex '>
                                        <img className='customheight' src={whitetick} />
                                        <p className='mb-0 pb-0 add_itlic_datas'>Covered by Insurance</p>
                                    </div>
                                    <div className='col-md col-9 mt-1 mt-md-0 d-flex'>
                                        <img className='customheight' src={whitetick} />
                                        <p className='mb-0 pb-0 add_itlic_datas'>Locations in your area </p>
                                    </div>
                                    <div className='col-md col-9  justify-content-md-start mt-1 mt-md-0 d-flex'>
                                        <img className='customheight' src={whitetick} />
                                        <p className='mb-0 pb-0 add_itlic_datas'>Free Consultations </p>
                                    </div>

                                </div>
                            </div>
                            <div className='col-12 col-md-4 text-center'>
                                <a type="button" href='tel:(216)450-1613 ' className="add_itlic_datas btn btn-outline-light rounded-pill mb-2 px-mb-5 px-2 py-md-3 py-2  btn_width" >
                                    <b className='text-white cll' >Call:(216)450-1613</b>
                                </a>

                                <button type="button" class="add_itlic_data size-set btn btn-light rounded-pill px-mb-5 px-2 py-md-3 py-2 mt-md-3 mt-1 btn_width customcolor  fw-bold" onClick={onConsultationClick} >Request Your Free Consultation</button>
                                {/* <button type="button" className="btn btn rounded-pill px-mb-5 px-2 py-md-3 py-2 mt-2 btn_width size-set customcolor btn-light fw-bold" onClick={onConsultationClick}>Request Your Free Consultation</button> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='container mt-2  mt-md-5 border-bottom  border-top border-3  border-seconder  pb-md-4 pb-3 px-md-0'>
                <div className='text-center '>
                    <h1 className='mb-4 customFont fs-1 add_head_data mt-4'><b>Meet Our Physicians </b></h1>
                </div>
                <div className='row ms-md-4 ms-md-0 mx-auto'>
                    <div className='col-md-4 col-12'>
                        <img src={amit} className='img_one img-fluid' />
                    </div>
                    <div className='col-md-7 moblie_center col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-none d-md-block'>
                        <h4><b className='border-bottom border-3 border-dark fs-5'>Amit Mohan, MD </b><br /></h4>
                        <h4 className='customcolor fs-6'> Executive Director</h4>
                        <p className='para_text add_itlic_data mt-md-0 mt-3'><b className='customcolor'>Dr. Amit Mohan</b>, an Executive Director at <b className='customcolor'>ARC Psychiatry and TMS Specialist</b>, is Board Certified in Adult Psychiatry, Sleep Medicine, and Addiction Medicine. He completed his residency training and internship at Mayo Clinic College of Medicine and subsequently did his fellowship in Sleep Medicine at Cleveland Clinic.

                            <br /><br />
                            Dr. Mohan is a distinguished member of The American Medical Association, American Academy of Sleep Medicine, and the <b className='customcolor'>American Psychiatric Association</b>. He provides medication management for adults 18 years of age and older, leveraging his extensive experience in both inpatient and outpatient settings to treat a wide range of mental disorders.
                            <br /><br />
                            Dr. Mohan has a keen interest and specialization in Transcranial Magnetic Stimulation (TMS), having completed a Visiting Fellowship in TMS at Duke University. His expertise in this non-invasive procedure highlights his commitment to incorporating innovative treatments for mental health disorders, particularly for patients who may not respond to traditional therapies.
                            <br /><br />
                            Beyond his professional achievements, Dr. Mohan enjoys a variety of hobbies that contribute to his holistic approach to wellness. He is an enthusiastic traveler, exploring new cultures and cuisines around the world. In his free time, he loves cooking, experimenting with different recipes and ingredients. Additionally, he is an avid hiker, finding peace and inspiration in nature. Dr. Mohan also enjoys reading, with a particular interest in historical biographies, mindfulness, self-help, and contemporary fiction, which provides him with a well-rounded perspective on life and humanity.
                        </p>

                        {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
                    </div>
                    <div className='col-md-7 moblie_center col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-block d-md-none'>
                        <h4><b className='border-bottom border-3 border-dark fs-5'>Amit Mohan, MD </b><br /></h4>
                        <h4 className='customcolor fs-6'> Executive Director</h4>
                        <p className='para_text add_itlic_data mt-md-0 mt-3'><b className='customcolor'>Dr. Amit Mohan</b>, an Executive Director at <b className='customcolor'>ARC Psychiatry and TMS Specialist</b>, is Board Certified in Adult Psychiatry, Sleep Medicine, and Addiction Medicine.
                            <span style={{ display: isExpanded ? 'none' : 'inline' }}>...</span>
                            <span style={{ display: isExpanded ? 'inline' : 'none' }}> He completed his residency training and internship at Mayo Clinic College of Medicine and subsequently did his fellowship in Sleep Medicine at Cleveland Clinic.

                                <br /><br />
                                Dr. Mohan is a distinguished member of The American Medical Association, American Academy of Sleep Medicine, and the <b className='customcolor'>American Psychiatric Association</b>. He provides medication management for adults 18 years of age and older, leveraging his extensive experience in both inpatient and outpatient settings to treat a wide range of mental disorders.
                                <br /><br />
                                Dr. Mohan has a keen interest and specialization in Transcranial Magnetic Stimulation (TMS), having completed a Visiting Fellowship in TMS at Duke University. His expertise in this non-invasive procedure highlights his commitment to incorporating innovative treatments for mental health disorders, particularly for patients who may not respond to traditional therapies.
                                <br /><br />
                                Beyond his professional achievements, Dr. Mohan enjoys a variety of hobbies that contribute to his holistic approach to wellness. He is an enthusiastic traveler, exploring new cultures and cuisines around the world. In his free time, he loves cooking, experimenting with different recipes and ingredients. Additionally, he is an avid hiker, finding peace and inspiration in nature. Dr. Mohan also enjoys reading, with a particular interest in historical biographies, mindfulness, self-help, and contemporary fiction, which provides him with a well-rounded perspective on life and humanity.
                            </span>
                            <button onClick={toggleReadMore} className=' bg-white  border-0' style={{ color: '#98C647 ' }}><b>
                                {isExpanded ? 'Read less' : 'Read more'}</b>
                            </button></p>

                        {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
                    </div>
                </div>
                <div className='row mt-4 ms-md-4 ms-md-0 mx-auto'>
                    <div className='col-md-4 col-12'>
                        <img src={boris} className='img_two img-fluid' />
                    </div>
                    <div className='col-md-7   moblie_center  col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-none d-md-block'>
                        <h4><b className='border-bottom border-3 border-dark fs-5'>Boris Royak, MD </b><br /></h4>
                        <h4 className='customcolor fs-6'> Executive Director</h4>
                        <p className='para_text add_itlic_data mb-0 mt-md-0 mt-3'>
                            <b className='customcolor'> Dr. Boris Royak</b>, a summa cum laude graduate of Case Western Reserve University, is an Executive Director of ARC Psychiatry. He completed his residency training in psychiatry at Brown University.<br /><br />
                            Dr. Royak is a Diplomate of the American Board of Psychiatry and Neurology and a member of the <b className='customcolor'>American Psychiatric Association</b>. With years of experience as a leader in healthcare systems, including roles as a Medical Director at hospitals and long-term care facilities, as well as a corporate consultant, he provides mental health services in a variety of settings. His areas of focus include Adult and Geriatric Psychiatry.<br /><br />
                            Dr. Royak has a profound interest in Transcranial Magnetic Stimulation (TMS), a cutting-edge treatment for mental health disorders. He completed a Visiting Fellowship in TMS at Duke University, where he honed his skills and deepened his understanding of this innovative therapy. Dr. Royak is dedicated to advancing the use of TMS to treat patients with depression and other mental health conditions, particularly those who have not responded to traditional treatments. His expertise in TMS is a testament to his commitment to offering the latest and most effective treatment options to his patients.<br /><br />
                            Outside of the office, Dr. Royak enjoys traveling and exploring new places, often capturing the beauty of his adventures through photography. His passion for discovering diverse cultures and landscapes enriches his approach to psychiatry, providing him with a broader perspective on the human experience
                        </p>
                        {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
                    </div>
                    <div className='col-md-7   moblie_center  col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-block d-md-none'>
                        <h4><b className='border-bottom border-3 border-dark fs-5'>Boris Royak, MD </b><br /></h4>
                        <h4 className='customcolor fs-6'> Executive Director</h4>
                        <p className='para_text add_itlic_data mb-0 mt-md-0 mt-3'>
                            <b className='customcolor'> Dr. Boris Royak</b>, a summa cum laude graduate of Case Western Reserve University, is an Executive Director of ARC Psychiatry. He completed his residency training in psychiatry at Brown University.<span style={{ display: isExpanded ? 'none' : 'inline' }}>...</span>
                            <span style={{ display: isExpanded ? 'inline' : 'none' }}>
                                Dr. Royak is a Diplomate of the American Board of Psychiatry and Neurology and a member of the <b className='customcolor'>American Psychiatric Association</b>. With years of experience as a leader in healthcare systems, including roles as a Medical Director at hospitals and long-term care facilities, as well as a corporate consultant, he provides mental health services in a variety of settings. His areas of focus include Adult and Geriatric Psychiatry.<br /><br />
                                Dr. Royak has a profound interest in Transcranial Magnetic Stimulation (TMS), a cutting-edge treatment for mental health disorders. He completed a Visiting Fellowship in TMS at Duke University, where he honed his skills and deepened his understanding of this innovative therapy. Dr. Royak is dedicated to advancing the use of TMS to treat patients with depression and other mental health conditions, particularly those who have not responded to traditional treatments. His expertise in TMS is a testament to his commitment to offering the latest and most effective treatment options to his patients.<br /><br />
                                Outside of the office, Dr. Royak enjoys traveling and exploring new places, often capturing the beauty of his adventures through photography. His passion for discovering diverse cultures and landscapes enriches his approach to psychiatry, providing him with a broader perspective on the human experience</span><button onClick={toggleReadMore} className=' bg-white  border-0 ' style={{ color: '#98C647 ' }}>
                                <b> {isExpanded ? 'Read less' : 'Read more'}</b>
                            </button></p>

                        {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
                    </div>
                </div>
            </div>
            <div className='container  border-bottom  border-3  border-seconder pb-3 '>
                <div className='row'>
                    <div className='col-md-6 p-md-5 pb-0 pt-4 align-items-center '>
                        <h1 className='customFont fw-bold fs-1 add_head_data px-md-0 px-3'>Get Your FREE Consultation or Talk to Us Now </h1>
                        <p className='para_text add_itlic_data mb-0 px-md-0 px-3 text-md-start text-center fw-blod'>Take this next step, we'll help with the others. </p>
                    </div>
                    <div className='col-md-4 col-12 text-center mx-auto my-auto mt-md-5 mt-3'>
                        <div class=" d-md-block d-none flex-column flex-md-row align-items-center justify-content-center">
                            <a type="button" class="btn btn-secondary text-white rounded-pill px-md-2 px-2 py-md-3 py-2 mb-2 mb-md-0 btn_width customclor1" href="tel:(216) 450-1613">
                                <b class="text-white">Call: (216) 450-1613</b>
                            </a>
                            <button type="button" class="btn btn-primary rounded-pill px-md-1 px-2 py-md-3 py-2  mt-2 btn_width customclor fw-bold" onClick={onConsultationClick}>
                                Request Your Free Consultation
                            </button>
                        </div>
                        <div class=" d-flex d-md-none d-block flex-column flex-md-row align-items-center justify-content-center">
                            <a type="button" class="btn btn-secondary text-white rounded-pill px-md-2 px-2 py-md-3 py-2 mb-1 mb-md-0 btn_width customclor1" href="tel:(216) 450-1613">
                                <b class="text-white">Call: (216) 450-1613</b>
                            </a>
                            <button type="button" class="btn btn-primary rounded-pill px-md-1 px-2 py-md-3 py-2  mt-md-0 btn_width customclor fw-bold" onClick={onConsultationClick}>
                                Request Your Free Consultation
                            </button>
                        </div>

                    </div>
                    {/* <div className='col-md-6 col-12 p-5'>
              <input placeholder='First Name ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <input placeholder='Last Name ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <input placeholder='Email ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <input placeholder='Phone Number ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <div className='text-center'>
                <button type="button" class="btn btn-color text-white px-4 fs-4 my-4 btnColor">Get More Info </button>
              </div>
            </div> */}
                </div>
            </div>
            <div className='container mb-5 fs-7'>
                <div className='text-center my-md-5 my-4'>
                    <img src={arc2} className='imgCLass' />
                </div>
                <div className='px-md-0 px-3'>
                    <p className='text-justify add_itlic_data'>The medical information on this site is provided as an information resource only, and is not to be used or relied on for any diagnostic or treatment purposes. This information is not intended to be patient education, does not create any patient-physician relationship, and should not be used as a substitute for professional diagnosis and treatment. Always consult your health care provider before making any healthcare decisions or for guidance about a specific medical condition. </p>
                    <p className='text-justify add_itlic_data'>TMS is indicated for the treatment of depressive episodes and for decreasing anxiety symptoms for those who may exhibit comorbid anxiety symptoms in adult patients suffering from Major Depressive Disorder (MDD) and who failed to achieve satisfactory improvement from previous antidepressant medication treatment in the current episode. TMS is intended to be used as an adjunct for the treatment of adult patients suffering from Obsessive-Compulsive Disorder (OCD) </p>
                    <p className='text-justify add_itlic_data mb-2'><b className=''>Important Safety Information </b></p>
                    <p className='text-justify add_itlic_data'>The most common side effect of TMS is pain or discomfort at or near the treatment site. These events are transient; they occur during the TMS treatment course and do not occur for most patients after the first week of treatment. There is a rare risk of seizure associated with the use of TMS therapy . </p>
                </div>
            </div>
            <div style={{ background: '#454d53' }}>
                <div className=' container text-center py-5 '>
                    <p className='px-2 copy'>
                        © Copyright {currentYear} All Rights Reserved by ARC Psychiatry
                    </p>        <div className='flex_rul justify-content-center copy  mx-5 '>
                        <div className='mx-4 '> <a target='_blank' href='https://arcproviders.com/terms-and-conditions/' className='copy py-1 footer_text' >Terms and Conditions</a></div>
                        <div className='mx-4'> <a target='_blank' href='https://arcproviders.com/privacy-policy-2/' className='copy py-1 footer_text'>Privacy Policy</a></div>
                        <div className='mx-4'> <a target='_blank' href='https://arcproviders.com/notice-of-privacy-practices/' className='copy py-1 footer_text'>Notice of Privacy Practices</a></div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Home