import React from 'react'
import { arc, arc2 } from '../../assets/img';
import './Nav.css';
import ConsultationForm from '../ConsultationForm/ConsultationForm';

const Navbar = ({show, handleClose, handleOpen}) => {
  return (
    <div className='sticky-top bg-white px-md-5 px-0'>
    {/* <nav className="navbar row navbar-expand-lg bg-body-white sticky-top px-5"> */}
    <nav className="navbar navbar-expand-lg bg-body-white sticky-top ">
      <div className="container  pb-md-4 py-2">
        <img src={arc2} alt="" className='logo'  style={{ cursor: 'pointer' }} />
        <div className=" d-flex justify-content-end" id="navbarSupportedContent">
          <div className="d-flex">
            <button className="btn btn-color fw-medium px-4 text-white chehck rounded-5" onClick={handleOpen} type="button" >Free Consultation</button>
          </div>
        </div>
      </div>
    </nav>
    <ConsultationForm handleCloseModal={handleClose} onConsultationClick={show} />
  </div>
  )
}

export default Navbar